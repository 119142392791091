<template>
	<div>
		<h1>Default</h1>
	</div>
</template>

<script>
	export default {
		name: 'Default'
	}
</script>

<style lang="scss" scoped>

</style>